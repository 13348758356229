<template>
  <div class="card">
    <div v-if="isLoading" class="skeletonContainer">
      <b-skeleton width="80%" :animated="true"></b-skeleton>
      <b-skeleton width="40%" :animated="true"></b-skeleton>
      <b-skeleton width="20%" :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
    </div>
    <div v-if="!isLoading">
      <div v-if="taskType === 'Routes'" class="targetListHeader">
        <div class="titleContainer">
<!--          <h4 class="header-title">{{ $t("monitoring.targets") }}</h4>-->
        </div>
        <div class="d-flex">
          <add-target
              class="m-r-10"
              targetType="location"
          />
          <location-task-create
          />
        </div>
      </div>

      <div class="scroll-container targetList" >
        <b-table
            v-if="targets.length"
            :items="targets"
            :fields="fields"
            :busy="isLoading"
            :tbody-tr-class="rowClass"
            small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(alias)="row">
            <div class="border-checkbox-section">
              <div class="border-checkbox-group border-checkbox-group-primary" style="display:flex;">
                <input class="border-checkbox" type="checkbox" :id="`target`+row.value" :disabled="!selectedTargets.includes(row.item.target_id) && selectedTargets.length > 4" :value="row.item.target_id" v-model="selectedTargets">
                <label class="border-checkbox-label" :for="`target`+row.value">
                  <span v-if="row.value.length <= 25">{{row.value}}</span>
                  <span v-else :class="{'hideText': row.value.length > 25}" :content="row.value" v-tippy="{ placement : 'top',  arrow: true  }">{{row.value}}</span>
                  <i class="feather icon-file-text f-16 m-l-10" v-if="row.item.note" :content="`<span class='f-w-700'>${$t('monitoring.note')}</span>`+': '+row.item.note" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                </label>
              </div>
            </div>
          </template>

          <template #cell(2)="row">
            <div style="display: flex">
              <div style="display: flex;justify-content: flex-start; align-items: center">
                <i class="feather icon-eye f-16" v-if="row.item.active"  :content="$t('monitoringLocation.status_tooltip_active')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                <i class="feather icon-eye-off f-16" v-else :content="$t('monitoringLocation.status_tooltip_disabled')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
              </div>
              <div style="display: flex;justify-content: flex-start; align-items: center" class="m-l-15 iconContainer">
                <i class="feather icon-info f-16" v-if="row.item.target.locations.length"  :content="$t('monitoringLocation.info_tooltip_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                <i class="feather icon-info crossed  f-16" v-else :content="$t('monitoringLocation.info_tooltip_not_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
              </div>
            </div>
          </template>

          <template #cell(1)="row">
            <target-actions
                :target="row.item"
                :taskType="taskType"
                :is-edit="isEdit"
                @anyloading="anyLoading"
                :some-loading="anyloading"
            />
          </template>
        </b-table>
      </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import TargetActions from "@/components/monitoring/targetActions";
import MonitoringIcons from "@/components/monitoring/monitoringIcons";
import AddTarget from "@/components/monitoring/addTarget";
import LocationTaskCreate from "@/components/monitoringLocation/locationTaskCreate";

export default {
  name: "locationTargetsList",
  components: {LocationTaskCreate, AddTarget, MonitoringIcons, TargetActions},
  props: ['taskType'],
  setup() {
    return {
      moment
    }
  },
  data(){
    return {
      isEdit: true,
      anyloading: false,
      isLoading: false,
      selectedTargets: []
    }
  },
  watch: {
    selectedTargets(){
      this.$emit('targetsListChange', this.selectedTargets)
    },
  },
  computed: {
    ...mapGetters('monitoring', ['targets', 'sources', 'currentMonitoringCount']),
    fields(){
          return  [
            {
              key: 'alias',
              label:  this.$t('monitoring.table_name'),
              sortable: false
            },
            {
              key: '2',
              label:  this.$t('monitoringLocation.active_status'),
              sortable: false
            },
            {
              key: '1',
              label:  this.$t('monitoring.table_action'),
              sortable: false,
              'class': 'actionsBlock',
            },
          ]
    },
  },
  methods: {
    rowClass(item){
      if (this.taskType === 'Routes'){
        let index = this.selectedTargets.findIndex((element) => item.target.id === element)
        if (index !== -1){
          return 'color-'+ index
        }
      }
    },
    getTargets() {
      let payload = {
        filters: {
          type: 'location'
        }
      }
      this.isLoading = true
      this.$store.dispatch('monitoring/getTargets', payload).then(() => {
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false;
        this.isError = true;
        this.errorText = err.response.data.message ? err.response.data.message : err.response.data.error
      })
    },
    anyLoading(val) {
      this.anyloading = val;
    }
  },
  mounted() {
    this.getTargets();
  }
}
</script>

<style lang="scss">
.iconContainer {
  position: relative;
  .crossed:after {
    content: "";
    position: absolute;
    left: 7px;
    top: -3px;
    right: 0;
    bottom: 0px;
    width: 1px;
    height: 22px;
    background-color: rgb(33, 37, 41);
    background-size: cover;
    transform: rotate(135deg);
  }
}



.skeletonContainer {
  padding: 8px;
}

.targetListHeader{
  display: flex;
  justify-content: space-between;
  padding: 8px 14px 8px 14px;
  .titleContainer{
    display: flex;
    align-items: center;
  }
}

  .scroll-container {
    max-height: 400px ;
    overflow-y: scroll;
    padding-bottom: 16px;
  }
.table.table-sm td{
  padding: 6px !important;
}
.targetList{
  padding: 8px;
}

</style>