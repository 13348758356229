<template>
    <span>
      <span>{{$t('monitoring.start_monitoring_for')}}</span>
      <b-form-select v-model="period" :options="periodList" style="margin-left: 8px; margin-right: 8px"></b-form-select>
      <span>{{$tc('monitoring.continue_day', period)}}</span>
    </span>
</template>

<script>
export default {
  name: "taskCreatePeriodSelect",
  data(){
    return {
      period: undefined,
      periodList: [3,7,10,14,21,30]
    }
  },
  watch: {
    period(){
      this.$emit('setPeriod', this.period)
    },
  },
}
</script>

<style scoped>

</style>