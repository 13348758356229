<template>
  <div class="container">

    <div class="row">
      <div class="col-md-6">
        <div class="card areas-list-card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <button class="btn btn-primary lower-button" @click="createAreaModal = true">{{ $t('geo.create_task') }}</button>
            </div>
            <b-overlay :show="isLoading">
              <zonesList v-model="selectedZones" :zones="areas" />
            </b-overlay>
          </div>
        </div>
        <!-- <zoneFilters /> -->
      </div>
      <div class="col-md-6">
        <mapZones :selectedZones="selectedZones" ref="mapZones"></mapZones>
      </div>
      <div class="col-md-12 m-t-15">
        <div class="card">
          <div class="card-body">
            <b-overlay :show="isLoadingLogs">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>{{ $t('monitoringLocation.area') }}</th>
                    <th>{{ $t('monitoringLocation.target') }}</th>
                    <th>{{ $t('monitoringLocation.period_from') }}</th>
                    <th>{{ $t('monitoringLocation.period_to') }}</th>
                  </tr>
                </thead>
                <tbody v-if="areaLogs.length">
                  <tr v-for="areaLog in areaLogs" >
                    <td>{{ areasObj[areaLog.task_id].title }}</td>
                    <td>{{ targets[areaLog.location.target_id].alias }} <a href="#" @click.prevent="setPoint(areaLog.location.location)"><i class="feather icon-crosshair"></i></a></td>
                    <td>{{ moment(areaLog.location.period_from, 'X').format('DD.MM.YYYY HH:mm:ss') }}</td>
                    <td>{{ moment(areaLog.location.period_to, 'X').format('DD.MM.YYYY HH:mm:ss') }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4">{{ $t('monitoringLocation.emptyList') }}</td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
        </div>
      </div>
      <b-modal size="xl" v-model="createAreaModal" id="add-area" centered hide-footer :title="$t('monitoringLocation.add_area')">
        <createArea ref="createArea" ></createArea>
      </b-modal>
    </div>
  </div>
</template>

<script>
import zonesList from './zonesList.vue';
import mapZones from './mapZones.vue';
import { mapGetters } from 'vuex';
import createArea from './createArea.vue';
import moment from 'moment';
import zoneFilters from './zoneFilters.vue';
import _ from 'underscore';

export default {
  name: "alarmZones",
  data() {
    return {
      zones: [
        
      ],
      selectedZones: [],
      isLoading: false,
      createAreaModal: false,
      isLoadingLogs: false,
    }
  },
  setup() {
    return {
      moment
    }
  },
  components: {
    zonesList,
    mapZones,
    createArea,
    zoneFilters
  },
  computed: {
    ...mapGetters('locationMonitoring', ['areas', 'areaLogs']),
    payload() {
      return {
        filters: {
          task_id: this.selectedZones
        }
      }
    },
    targets() {
      return _.indexBy(_.flatten(this.areas.map(item => item.targets)), 'target_id')
    },
    areasObj() {
      return _.indexBy(this.areas, 'id')
    }
  },
  methods:{
    getAreas() {
      this.isLoading = true
      this.$store.dispatch('locationMonitoring/getAreas').then(() => {
        this.isLoading = false
      })
    },
    getAreaTrackingLogs() {
      this.isLoadingLogs = true
      this.$store.dispatch('locationMonitoring/areaTrackingLogs', this.payload)
        .then(() => {
          this.isLoadingLogs = false
        })
    },
    clearFilters() {
      this.isLoadingLogs = true;
      setTimeout(() => {
        this.isLoadingLogs = false;
        this.$store.commit('locationMonitoring/setAreasLogs', [])
      }, 1000)
    },
    setPoint(location) {
      this.$refs.mapZones.setMarker(location)
    }
  },
  watch: {
    createAreaModal(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.createArea.updateMap()
        }, 100)
      }
    },
    selectedZones(val) {
      if(val.length > 0) {
        this.getAreaTrackingLogs()
      } else {
        this.clearFilters()
      }
    }
  },
  mounted() {
    this.getAreas()
  }
}
</script>

<style scoped>
.areas-list-card {
  height: 500px;
  overflow-y: auto;
}
</style>