<template>
  <div class="intersectionsTaskCreate">
    <div v-if="isLoading" class="skeletonContainer">
      <b-skeleton width="80%" :animated="true"></b-skeleton>
      <b-skeleton width="40%" :animated="true"></b-skeleton>
      <b-skeleton width="20%" :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
    </div>
    <div class="row" v-if="!isLoading">
      <div class="contentBlock col-6">
        <div style="height: 22px">
          <span style="font-weight: 600; margin-left: 12px" v-if="selectedTargets.length < 2">Оберіть пару цілей</span>
        </div>
        <div class="scroll-container targetList">

          <b-table
              v-if="targets.length"
              :items="filteredTargets"
              :fields="fields"
              :busy="isLoading"
              small
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(alias)="row">
              <div class="border-checkbox-section">
                <div class="border-checkbox-group border-checkbox-group-primary" style="display:flex;">
                  <input class="border-checkbox"
                         type="checkbox"
                         :id="`taskCreate`+row.value"
                         :disabled="(!selectedTargets.includes(row.item.id) && selectedTargets.length > 1 ) || row.item.canBeSelected"
                         :value="row.item.id"
                         v-model="selectedTargets">
                  <label class="border-checkbox-label" :for="`taskCreate`+row.value">
                    <span v-if="row.value.length <= 25">{{row.value}}</span>
                    <span v-else :class="{'hideText': row.value.length > 25}" :content="row.value" v-tippy="{ placement : 'top',  arrow: true  }">{{row.value}}</span>
                    <i class="feather icon-file-text f-16 m-l-10" v-if="row.item.note" :content="`<span class='f-w-700'>${$t('monitoring.note')}</span>`+': '+row.item.note" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                  </label>
                </div>
              </div>
            </template>

            <template #cell(2)="row">
              <div style="display: flex">
                <div style="display: flex;justify-content: flex-start; align-items: center">
                  <i class="feather icon-eye f-16" v-if="row.item.active"  :content="$t('monitoringLocation.status_tooltip_active')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                  <i class="feather icon-eye-off f-16" v-else :content="$t('monitoringLocation.status_tooltip_disabled')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                </div>
                <div style="display: flex;justify-content: flex-start; align-items: center" class="m-l-15 iconContainer">
                  <i class="feather icon-info f-16" v-if="row.item.target.locations.length"  :content="$t('monitoringLocation.info_tooltip_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                  <i class="feather icon-info crossed  f-16" v-else :content="$t('monitoringLocation.info_tooltip_not_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                </div>
              </div>
            </template>
          </b-table>



        </div>
      </div>
      <div class="contentBlock col-6">
        <div style="height: 22px">
          <span style="font-weight: 600; margin-left: 12px">Налаштування</span>
        </div>
        <div class="settingsList" style="margin-top: 10px">
            <div class="settingsBlock">
              <label for="range-1" style="width: 100%">
                <div style="display: flex; justify-content: space-between; margin-bottom: 8px">
                  <span> Distance:</span>
                  <span> {{ distance }} м</span>
                </div>
              </label>
              <b-form-input :disabled="selectedTargets.length !== 2" style="width: 100%" id="range-1" v-model="distance" type="range" min="300" max="1000"></b-form-input>
            </div>

          <div class="settingsBlock">
            <div style="display: flex; justify-content: start;margin-bottom: 8px">
                <span> Дата завершення спостереження:</span>
            </div>
            <date-picker v-model="taskEndDate"
                         type="date"
                         :placeholder="$t('settings.datePlaceholder')"
                         valueType="YYYY-MM-DD"
                         format="DD.MM.YYYY"
                         :editable="false"
                         :lang="lang[$i18n.locale]"
                         :disabled-date="disabledRange"
                         :disabled="selectedTargets.length !== 2"></date-picker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DatePicker from 'vue2-datepicker';
import moment from "moment";


export default {
  name: "intersections-task-create",
  components: { DatePicker },
  data(){
    return {
      isLoading: false,
      distance: 300,
      selectedTargets: [],
      taskEndDate: '',
      lang: {
        ua: {
          formatLocale: {
            months: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
            monthsShort: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
            weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
            weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 7
          }
        },
        ru: {
          formatLocale: {
            months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
            monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
            weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1
          }
        },
        en: {
          formatLocale: {
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            firstDayOfWeek: 0,
            firstWeekContainsDate: 1
          }
        }
      },
      disabledTargetsIds: [],
    }
  },
  computed: {
    ...mapGetters('monitoring', ['targets']),
    ...mapGetters('locationMonitoring', ['crossTrackingTasks']),
     filteredTargets() {
       let arr = []
        if (this.targets.length && this.selectedTargets) {
          this.targetCanBeSelected()
          this.targets.forEach(target => {
            if (target.target.param === 'isdn') {
              let obj = target
              obj.canBeSelected = this.disabledTargetsIds.includes(target.id);
              arr.push(obj)
            }
          })
          return arr
        }
    },
    fields(){
      return  [
        {
          key: 'alias',
          label:  this.$t('monitoring.table_name'),
          sortable: false
        },
        {
          key: '2',
          label:  this.$t('monitoringLocation.active_status'),
          sortable: false
        },
      ]
    },
  },
  watch: {
    selectedTargets(){
      this.isLoading = true;
      this.$emit('setSelectedTarget', this.selectedTargets)
      this.isLoading = false;
    },
    distance(){
      this.$emit('setDistance', this.distance)
    },
    taskEndDate(){
      this.$emit('setTaskEndDate', this.taskEndDate)
    },
  },
  methods: {
     targetCanBeSelected(){
      let disabledTargetsIds = []
      if (this.selectedTargets.length === 0){
        this.disabledTargetsIds = []
      } else {
        this.crossTrackingTasks.forEach(task=> {
          console.log(task)
          let taskIds = []
          task.targets.forEach(taskTarget => {
            taskIds.push(taskTarget.subscription_id)
          })
          if (taskIds.includes(this.selectedTargets[0])){
            taskIds.forEach(id=> {
              if (id !== this.selectedTargets[0]){
                disabledTargetsIds.push(id)
              }
            })
          }
        })
        this.disabledTargetsIds = disabledTargetsIds
      }
    },
    clearSettings(){
      this.distance = 300;
      this.taskEndDate = '';
      this.selectedTargets = [];
    },
    disabledRange(date) {
      return  moment().toDate() > date;
    },
  }
}
</script>

<style lang="scss">
.intersectionsTaskCreate{
  .iconContainer {
    position: relative;
    .crossed:after {
      content: "";
      position: absolute;
      left: 7px;
      top: -3px;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 22px;
      background-color: rgb(33, 37, 41);
      background-size: cover;
      transform: rotate(135deg);
    }
  }

  .skeletonContainer {
    padding: 8px;
  }

  .targetListHeader{
    display: flex;
    justify-content: space-between;
    padding: 8px 14px 0 14px;
    .titleContainer{
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 991px) {
    .scroll-container {
      overflow-y: scroll;
      padding-bottom: 30px;
    }
  }
  .table.table-sm td{
    padding: 6px !important;
  }
  .targetList{
    padding: 8px;
  }
  .settingsList {
    padding: 8px;
  }
  .scroll-container {
    height: 225px ;
    overflow-y: scroll;
    padding-bottom: 16px;
  }
}
</style>