<template>
    <div>
        <table class="table table-sm" v-if="zones.length">
            <thead>
                <tr>
                    <th></th>
                    <th>{{ $t('monitoringLocation.title') }}</th>
                    <th>{{ $t('monitoringLocation.targets') }}</th>
                    <th>{{ $t('monitoringLocation.actions') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(zone, index) in zones" :key="zone.id">
                    <td class="checkbox-col border-checkbox-section">
                        <div class="border-checkbox-group border-checkbox-group-primary">
                            <input class="border-checkbox" type="checkbox" :id="`zone_${zone.id}`" :value="zone.id" v-model="selectedZones">
                            <label class="form-label border-checkbox-label" :for="`zone_${zone.id}`"></label>
                        </div>
                    </td>
                    <td>
                        <p><strong>{{ zone.title }}</strong>
                            <i class="feather icon-file-text f-16 m-l-10" v-if="zone.note" :content="`<span class='f-w-700'>${$t('monitoring.note')}</span>`+': '+zone.note" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                        </p>
                    </td>
                    <td>
                        <ul>
                            <li v-for="target in zone.targets">
                                <strong>{{ target.alias }}</strong><br>{{ target.param }}: {{ target.value }}
                            </li>
                        </ul>
                    </td>
                    <td class="actions-col">
                        <div>
                            <!-- <button class="action-btn-target" v-b-modal="'delete_modal'+zone.id">
                                <i class="feather icon-trash-2 f-w-600 f-16 text-c-red m-r-15" :content="$t('monitoring.delete_target')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                            </button> -->

                            <button class="action-btn-target" style="" v-b-modal="`edit-area-${zone.id}`">
                                <i class="feather icon-edit f-w-600 f-16 editIconColor" :content="$t('monitoringLocation.edit_area')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                            </button>
                        </div>
                    </td>
                    <b-modal :id="`edit-area-${zone.id}`" size="xl" centered hide-footer :title="$t('monitoringLocation.edit_area')">
                        <editArea :area="zone" @closeAreaModal="$bvModal.hide(`edit-area-${zone.id}`)" />
                    </b-modal>
                </tr>
            </tbody>
        </table>
        <div v-else>
            <p>{{ $t('monitoringLocation.emptyList') }}</p>
        </div>
    </div>
</template>
<script>
    import editArea from './editArea.vue'
    export default {
        name: 'zoneList',
        props: {
            value: {
                type: Array,
                default: () => []
            },
            zones: {
                type: Array,
                default: () => []
            }
        },
        components: {
            editArea
        },
        data() {
            return {
                selectedZones: this.value
            }
        },
        watch: {
            selectedZones() {
                this.$emit('input', this.selectedZones)
            }
        },
        methods: {
            closeAreaModal() {
                this.createAreaModal = false
            }
        }
    }
</script>
<style>
    .action-btn-target {
        display: flex;
        align-items: center;
        cursor: pointer
    }
    .checkbox-col {
        width: 40px;
    }
    .actions-col {
        width: 40px;
    }
</style>