<template>
  <div>
    <div class="skeletonContainer">
      <b-skeleton width="80%" :animated="true"></b-skeleton>
      <b-skeleton width="40%" :animated="true"></b-skeleton>
      <b-skeleton width="20%" :animated="true"></b-skeleton>
      <b-skeleton width="80%" :animated="true"></b-skeleton>
      <b-skeleton width="10%" :animated="true"></b-skeleton>
      <b-skeleton width="50%" :animated="true"></b-skeleton>
    </div>
    <div class="devPlaceholder card">{{$t('monitoringLocation.in_development')}}</div>
    <div class="skeletonContainer">
      <b-skeleton width="60%" :animated="true"></b-skeleton>
      <b-skeleton width="40%" :animated="true"></b-skeleton>
      <b-skeleton width="80%" :animated="true"></b-skeleton>
      <b-skeleton width="20%" :animated="true"></b-skeleton>
      <b-skeleton width="70%" :animated="true"></b-skeleton>
      <b-skeleton width="10%"  :animated="true"></b-skeleton>
    </div>
  </div>
</template>

<script>
export default {
  name: "visitPlaces"
}
</script>

<style scoped>
.devPlaceholder{
  color: rgba(0, 0, 0, 0.25);
  margin: 8px;
  min-height: 50px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>