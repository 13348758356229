<template>
  <div class="tabContainer">
      <div class="container">
        <div class="row">
          <div class="contentBlock col-6">
              <location-targets-list
                taskType = "Routes"
                @targetsListChange = targetsListChange
              />

              <div class="card">
                <div class="block rangeSelectBlock">
                  <div class="rangeSelectBlockTitle"><h4>{{ $t('billing.period_selection') }}</h4></div>
                  <date-range-picker
                      ref="picker"
                      :ranges="calendarTranslate.ranges[$i18n.locale]"
                      v-model="dateRange"
                      :opens="'right'"
                      :locale-data="calendarTranslate.calendar[$i18n.locale]"
                  >
                    <template v-slot:input="picker">
                      <i class="feather icon-calendar m-r-5"></i>
                      {{ moment(picker.startDate).format('DD.MM.YYYY') }} - {{ moment(picker.endDate).format('DD.MM.YYYY') }}
                    </template>
                    <div slot="footer" slot-scope="data" class="slot d-lg-flex justify-content-xl-end">
                      <div class="d-flex align-items-center">
                        <b >{{ data.rangeText }}</b>
                      </div>
                      <div class="d-grid p-10 d-lg-flex ">
                        <b-button @click="data.clickApply" variant="primary" v-if="!data.in_selection" class="acceptButton btn">{{ $t('billing.applyLabel') }}</b-button>
                        <button @click="data.clickCancel" class="btn btn-secondary">{{ $t('billing.cancelLabel') }}</button>
                      </div>
                    </div>
                  </date-range-picker>
                </div>
              </div>


              <div class="card paginationCard" v-if="typeof routes.count !== 'undefined' && routes.total > routes.count">
                <div class="block paginationBlock">
                  <span>
                    <span v-html="$tc('geo.total_find', routes.total, {n: routes.total} )"></span>
                    <span v-if="routes.count > 1">
                    <span>, </span>
                    <span  v-html="$tc('result.total_show', routes.count , {n : routes.count})"></span>
                    </span>
                    <span v-else-if="routes.count === 1">
                    <span>, </span>
                    <span  v-html="$tc('result.one_show', routes.count , {n : routes.count})"></span>
                    </span>
                  </span>
                  <button class="btn btn-primary" @click="paginate">
                    <b-spinner small v-if="isPaginate"></b-spinner>
                    <span style="text-transform: none">{{ $t('geo.show_more') }}</span>
                  </button>
                </div>

              </div>


<!--              <div class="card">-->
<!--                <div class="block optionsBlock">-->
<!--                  <div class="center"><h4>{{ $t('search_list_item.options') }}</h4></div>-->
<!--                </div>-->
<!--              </div>-->
          </div>
          <div class="contentBlock col-6">
              <div class="mapBlock">
                <div id="mapLocation" style="height: 500px;"></div>
              </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Targets from "@/components/monitoring/targets";
import LocationTargetsList from "@/components/monitoringLocation/locationTargetsList";
import DateRangePicker from 'vue2-daterange-picker'
import moment from "moment";
import calendarTranslate from "@/configs/calendarTranslate";
import filters from "@/mixins/filters";
import "@/components/billing/pickerstyle.css"
import drawLocales from "leaflet-draw-locales";
import L from "leaflet";
import {mapGetters} from "vuex";
import _ from "underscore";


const wkt = require('wkt');
const { parse } = require('wkt');

export default {
  name: "Routes",
  components: {LocationTargetsList, Targets, DateRangePicker},
  mixins: [filters],
  setup() {
    const capitalize = (value) =>
    {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return {
      moment,
      calendarTranslate,
      capitalize
    }
  },
  data() {
    return {
      targetsList: [],
      dateRange: {
        startDate: moment().subtract(6, 'days').toDate(),
        endDate:   moment().toDate(),
      },
      url: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 5,
      center: [55.019531, 41.71803],
      bounds: null,
      map: null,
      locale: this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale,
      startZoom: 3,
      dateChange: false,
      colors: ['rgba(0,123,255,0.6)','rgba(40,167,69,0.6)','rgba(23,162,184,0.6)', 'rgba(220,53,69,0.6)', 'rgba(55,71,79,0.6)'],
      isPaginate: false
    }
  },
  computed: {
    ...mapGetters('locationMonitoring', ['routes']),
  },
  beforeDestroy() {
    this.$store.dispatch('locationMonitoring/clearTargetsLocations');
  },
  watch: {
    'dateRange.endDate': function (){
      if(!this.dateChange){
        this.dateChange = true;
        this.getTargetsLocations()
      }
    },
    'dateRange.startDate': function (){
      if(!this.dateChange){
        this.dateChange = true;
        this.getTargetsLocations()
      }
    },
  },
  methods: {
    paginate(){
      if (this.targetsList.length){
        let payload = {
          filters: {
            target_id : this.targetsList,
            period_from: moment(this.dateRange.startDate).startOf('day').format('X'),
            period_to: moment(this.dateRange.endDate).endOf('day').format('X'),
          },
          offset: this.routes.count
        }
        this.isPaginate = true
        this.$store.dispatch('locationMonitoring/targetLocationsPagination', payload).then(()=>{
          this.isPaginate = false;
          this.setPolylines()
          this. setPoints()
        })
      }
    },


    getTargetIndex(currentTarget){
      this.targetsList.forEach((target, index) => {
        if (currentTarget.id === target.id){
          return index
        }
      })
    },
    targetsListChange(list){
      this.targetsList = list;
      this.clearMap();
      this.getTargetsLocations()
    },
    clearMap() {
      if (this.drawnItems){
        this.drawnItems.eachLayer(layer => {
          this.drawnItems.removeLayer(layer);
        });
      }
    },

    getTargetsLocations(){
      if (this.targetsList.length){
        let payload = {
          filters: {
            target_id : this.targetsList,
            period_from: moment(this.dateRange.startDate).startOf('day').format('X'),
            period_to: moment(this.dateRange.endDate).endOf('day').format('X'),
          }
        }
        this.$store.dispatch('locationMonitoring/getTargetsLocations', payload).then(()=>{
          this.dateChange = false;
          this.setPolylines()
          this. setPoints()
        })
      }
    },


    setPolylines(){
      if (this.routes.data.length){
        let coords = _.groupBy(this.routes.data, 'target_id');
        for (const target of Object.keys(coords)){
          let index = this.targetsList.indexOf(coords[target][0].target_id);
          let arr = this.polylineCoords(coords[target]);
          L.polyline(arr, {color: this.colors[index]}).addTo(this.drawnItems);
        }
      }
    },

    setPoints() {
      let coords = _.groupBy(this.routes.data, 'target_id');
      for (const target of Object.keys(coords)){
        let index = this.targetsList.indexOf(coords[target][0].target_id);
        coords[target].forEach((point) => {
          const svgTemplate = `<div class="monitoringPin color-${index}" > <div class="marker-icon"></div></div>`;
          const icon = L.divIcon({
            className: "custom-div-icon",
            html: svgTemplate,
            iconSize: [8, 8],
            iconAnchor: [4, 4],
            popupAnchor: [0, -2]
          });

          let pointCoordinates = wkt.parse(point.location)
          pointCoordinates.coordinates.reverse();
          let marker = L.marker(pointCoordinates.coordinates, {
            icon: icon
          });
          marker.bindTooltip(this.pintTooltip(point), {
            direction: 'top',
            offset: L.point(1, -6)
          });
          marker.addTo(this.drawnItems);
          this.map.fitBounds(this.drawnItems.getBounds());
        });
      }
    },
    polylineCoords(coordsArr){
      let arr = [];
      coordsArr.forEach(item => {
        let point = wkt.parse(item.location)
        arr.push(point.coordinates.reverse())
      })
      return arr;
    },
    pintTooltip(point){
      let text = ''
      let createdTime = moment(point.period_from, 'X').format('DD.MM.YYYY, HH:mm')
      let updatedTime = moment(point.period_to, 'X').format('DD.MM.YYYY, HH:mm')
      text += 'Створено: '+ createdTime + `<br>` + 'Оновленно: ' + updatedTime
      return text
    },
    async initLocationMap() {
      const locale = drawLocales(this.locale)
      L.drawLocal = locale;
      L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;

      this.map = await L.map('mapLocation', {
        zoomControl: false,
        attributionControl: false,
        center: this.center,
        zoom: this.startZoom
      });

      L.tileLayer(this.url, { maxZoom: 19, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map)
      L.control.zoom({
        zoomInTitle: this.$t('geo.zoomIn'),
        zoomOutTitle: this.$t('geo.zoomOut')
      }).addTo(this.map)

      var southWest = L.latLng(-89.98155760646617, -180),
          northEast = L.latLng(89.99346179538875, 180);
      var bounds = L.latLngBounds(southWest, northEast);

      this.map.setMaxBounds(bounds);
      this.map.on('drag', () => {
        this.map.panInsideBounds(bounds, { animate: false });
      });
      this.drawnItems = new L.FeatureGroup().addTo(this.map);
    },
  },
  mounted() {
    this.initLocationMap()
  }
}
</script>

<style lang="scss">
  .monitoringPin{
    //margin-left: 8px;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    //background-color: rgba(13,110,253,0.4);
    background-size: contain;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: black;
    .marker-icon{
      position: absolute;
      bottom: 2px;
      left: 2px;
      width: 4px;
      height: 4px;
      text-align: center;
      color: black;
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }
  .tabContainer {
    .card {
      margin-bottom: 8px;
    }
    .paginationCard {
      padding: 8px 14px;
      .paginationBlock{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    h4 {
      margin-bottom: 0;
    }
  }

  .rangeSelectBlock{
    display: flex;
    justify-content: space-between;
    padding: 8px 14px 8px 14px;

    .rangeSelectBlockTitle{
      display: flex;
      align-items: center;
    }
  }

  .optionsBlock{
    display: flex;
    justify-content: start;
    padding: 8px 14px 8px 14px;
  }

  .mapBlock{
    padding-left: 14px;
  }


  .tabContainer{
    padding: 8px;
    .contentBlock{
      padding: 0 !important;
    }
  }
  body .daterangepicker .acceptButton{
    background-color: $primary-color !important;
  }

  body .daterangepicker .acceptButton:hover{
    background-color: #73b4ff !important;
  }
  .tables{
    .nav-link{
      color: rgb(64, 153, 255);
    }
  }
  .daterangepicker td.active{
    background-color: #4099ff;
  }
  .daterangepicker td.active:hover{
    background-color: #73b4ff;
  }

  .daterangepicker .ranges .active {
    background-color: $primary-color !important;
  }
  .daterangepicker .ranges .active:hover {
    background-color: #73b4ff !important;
  }

  .color-0 {
    background-color: rgba(0, 123, 255, 0.6);
  }

  .color-1 {
    background-color: rgba(40, 167, 69, 0.6);
  }

  .color-2 {
    background-color: rgba(23, 162, 184, 0.6);
  }

  .color-3 {
    background-color: rgba(220, 53, 69, 0.6);
  }

  .color-4 {
    background-color: rgba(55, 71, 79, 0.6);
  }


</style>