<template>
  <div>
    <button class="btn btn-primary" v-b-modal.create-task>
      <span style="text-transform: none">{{ $t("geo.create_task") }}</span>
    </button>

    <b-modal class="createTaskDialog" size="lg" v-model="visible" id="create-task" centered hide-footer :title="this.$t('geo.create_task')">
      <div>
<!--        <div class="form-group">-->
<!--              <select class="form-select"  v-model="selectedTaskType">-->
<!--                <option :value="null">{{ $t('geo.select_task_type') }}</option>-->
<!--                <option v-for="type in allTaskTypes" :key="'option'+type" :value="type">{{ $t('monitoringLocation.'+ type) }}</option>-->
<!--              </select>-->
<!--        </div>-->
        <routes-task-create
          @setSelectedTarget="setSelectedTarget"
          @setPeriod="setPeriod"
        >
          <!--          v-if="selectedTaskType === 'routes'"-->

        </routes-task-create>
<!--        <intersections-task-create-->
<!--            v-if="selectedTaskType === 'intersections'"-->
<!--            @setSelectedTarget="setSelectedTarget"-->
<!--            @setDistance="setDistance"-->
<!--            @setTaskEndDate="setTaskEndDate"-->
<!--        />-->

        <footer class="d-grid gap-2 m-t-15">
          <b-button :disabled="!enableCreateButton" variant="primary" class="btn" @click="createTask">{{ $t('geo.create') }}
            <span v-if="isLoading">
              <b-spinner small></b-spinner>
            </span>
          </b-button>
          <b-button type="button" variant="secondary"
                    @click="closeDialog" class="btn">{{ $t('user.cancel') }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RoutesTaskCreate from "@/components/monitoringLocation/routesTaskCreate";
import IntersectionsTaskCreate from "@/components/monitoringLocation/intersections-task-create";
import moment from "moment";

export default {
  name: "locationTaskCreate",
  components: {IntersectionsTaskCreate, RoutesTaskCreate},
  data() {
    return {
      visible: false,
      isLoading: false,
      // selectedTaskType: null,
      selectedTarget: [],
      period: null,
      // allTaskTypes: ['routes','alarmZones','intersections'],
      distance: 300,
      taskEndDate: ''
    }
  },
  watch: {
    visible(){
      if (!this.visible){
        // this.selectedTaskType = null
        this.selectedTarget = [];
      }
    },
  },
  computed: {
    enableCreateButton(){
      // if (this.selectedTaskType){
      //   if (this.selectedTaskType === 'routes' && this.selectedTarget.length){
        if (this.selectedTarget.length > 0){
          return true
        }
        // else if (this.selectedTaskType === 'intersections' && this.selectedTarget.length === 2){
        //   return true
        // }
      // } else {
      //   return false
      }

    // },
  },

  methods: {
    setPeriod(val){
      this.period = val
    },
    showDialog() {
      this.visible = !this.visible;
    },
    closeDialog() {
      // this.selectedTaskType = null;
      this.selectedTarget = [];
      this.visible = false;
    },
    setSelectedTarget(val) {
      this.selectedTarget = val;
    },
    // setDistance(val){
    //   this.distance = val
    // },
    // setTaskEndDate(val){
    //   this.taskEndDate = val;
    // },
    createTask(){
      // if (this.selectedTaskType === 'routes'){
        let payload = {
          type: 'location',
          id: this.selectedTarget[0],
          service_ids: ["5"]
        }
        if (this.period !== 0){
          payload.period = parseInt(this.period)
        }
        this.$store.dispatch('locationMonitoring/createMonitoringTask', payload)
      // }
      // else if (this.selectedTaskType === 'intersections'){
      //   let payload = {
      //     target_ids: this.selectedTarget,
      //     distance: this.distance,
      //     expires_at:  parseInt(moment(this.taskEndDate).format("X")),
      //   }
      //   if (payload.taskEndDate === ''){
      //     delete payload.expires_at
      //   }
      //   this.$store.dispatch('locationMonitoring/createCrossTrackingTask', payload)
      // }
    },
  }
}
</script>

<style>
.form-select {
  width: 100%;
  border-radius: 2px;
  height: 35px;
}
</style>