<template>
    <div class="card">
      <b-tabs content-class="mt-3" fill :lazy="true">
        <b-tab :title="$t('monitoringLocation.routes')" active>
          <routes></routes>
        </b-tab>
        <b-tab :title="$t('monitoringLocation.alarmZones')">
          <alarm-zones></alarm-zones>
        </b-tab>
        <b-tab :title="$t('monitoringLocation.intersections')">
          <intersections></intersections>
        </b-tab>
        <b-tab :title="$t('monitoringLocation.visitPlaces')">
          <visit-places></visit-places>
        </b-tab>
      </b-tabs>
    </div>
</template>

<script>
import Targets from "@/components/monitoring/targets";
import MonitoringFilters from "@/components/monitoring/monitoringFilters";
import {mapGetters} from "vuex";
import Routes from "@/components/monitoringLocation/Routes";
import AlarmZones from "@/components/monitoringLocation/alarmZones";
import Intersections from "@/components/monitoringLocation/intersections";
import VisitPlaces from "@/components/monitoringLocation/visitPlaces";
export default {
  name: "MonitoringLocation",
  components: {VisitPlaces, Intersections, AlarmZones, Routes, MonitoringFilters, Targets},
  data() {
    return {
      targetId: null,
      searchInfo: [],
      isLoading: false,
      isError: false,
      errorText: ""
    }
  },
  methods: {

  },
  mounted() {
    if (!this.permissions.includes('monitoring.location')) {
      this.$router.push({name: 'Home'})
    }
  },
  computed: {
    ...mapGetters('auth', ['permissions'])
  }
}
</script>
<style scoped>

</style>