<template>
  <div class="tabContainer">
      <div class="container">
        <div class="row">
          <div class="contentBlock col-6">
            <div class="card">
              <div v-if="isLoading" class="skeletonContainer">
                <b-skeleton width="80%" :animated="true"></b-skeleton>
                <b-skeleton width="40%" :animated="true"></b-skeleton>
                <b-skeleton width="20%" :animated="true"></b-skeleton>
                <b-skeleton :animated="true"></b-skeleton>
              </div>
              <div v-if="!isLoading">
                <div class="targetListHeader">
<!--                  <div class="titleContainer">-->
<!--                    <h4 class="header-title">Список пар целей</h4>-->
<!--                  </div>-->
                  <intersection-task-create-modal
                    @updateList="getAreaTrackingTask"
                  />
                </div>

                <div class="scroll-container targetList" >
                  <b-table
                      v-if="crossTrackingTasks.length"
                      :items="crossTrackingTasks"
                      :fields="fields"
                      :busy="isLoading"
                      :tbody-tr-class="rowClass"
                      small
                  >
                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>

                    <template #cell(1)="row">
                      <div class="border-checkbox-section">
                        <div class="border-checkbox-group border-checkbox-group-primary" style="display:flex;">
                          <input class="border-checkbox"
                                 type="checkbox"
                                 :id="`taskCreate`+row.item.id"
                                 :disabled="!selectedPairs.includes(row.item.id) && selectedPairs.length > 0 "
                                 :value="row.item.id"
                                 v-model="selectedPairs">
                          <label class="border-checkbox-label" :for="`taskCreate`+row.item.id" style="display: inline-flex">
                            <div class="targetsPairs"  v-for="(target, index) in row.item.targets" :key="index">
                              <div  :class="[`color-tag-${index}` ]">
                                <span v-if="target.alias && target.alias.length <= 25">{{target.alias}} </span>
                                <span v-else :class="{'hideText': target.value.alias > 25}" :content="target.alias" v-tippy="{ placement : 'top',  arrow: true }">{{target.alias}} </span>
                                <i class="feather icon-file-text f-16 m-l-10" v-if="target.note" :content="`<span class='f-w-700'>${$t('monitoring.note')}</span>`+': '+target.note" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </template>

                    <template #cell(distance)="row">
                        <div>{{row.value}} {{$t('geo.meters')}}</div>
                    </template>


<!--                    <template #cell(2)="row">-->
<!--                      <div style="display: flex;justify-content: center;">-->

<!--                          <span class="m-r-15" v-if="!row.item.status" @click="startSubscription(row.item)" :content="$t('monitoringLocation.start_task')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">-->
<!--                            <i class="feather icon-play f-w-600 f-16 playColor " @click="startSubscription(row.item)"></i>-->
<!--                          </span>-->

<!--                          <span class="m-r-15" v-if="row.item.status" @click="stopSubscription" :content="$t('monitoringLocation.stop_task')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">-->
<!--                            <i class="feather icon-pause f-w-600 f-16 stopColor"></i>-->
<!--                          </span>-->

<!--                          <button class="action-btn-target" @click="editSubscription" style="display: flex;align-items: center;cursor: pointer">-->
<!--                            <i class="feather icon-edit f-w-600 f-16 editIconColor" :content="$t('monitoringLocation.edit_task')" v-tippy="{ placement : 'top',  arrow: true }"></i>-->
<!--                          </button>-->

<!--                      </div>-->





<!--                    </template>-->


                  </b-table>
                </div>
              </div>
            </div>

          </div>
          <div class="contentBlock col-6">
            <div class="mapBlock">
              <div id="mapIntersections" style="height: 500px;"></div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import drawLocales from "leaflet-draw-locales";
import L from "leaflet";
import moment from "moment";
import {mapGetters} from "vuex";
import IntersectionsTaskCreate from "@/components/monitoringLocation/intersections-task-create";
import IntersectionTaskCreateModal from "@/components/monitoringLocation/intersectionTaskCreateModal";
import _ from "underscore";
import wkt from "wkt";
import i18n from "@/i18n";

export default {
  name: "intersections",
  components: {IntersectionTaskCreateModal, IntersectionsTaskCreate},
  data() {
    return {
      url: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 5,
      center: [55.019531, 41.71803],
      bounds: null,
      map: null,
      locale: this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale,
      startZoom: 3,
      isLoading: false,
      selectedPairs : [],
      selectedTargetsIds: [],
      colors: ['rgba(0,123,255,0.6)','rgba(40,167,69,0.6)','rgba(23,162,184,0.6)', 'rgba(220,53,69,0.6)', 'rgba(55,71,79,0.6)'],
    }
  },
  computed: {
    ...mapGetters('locationMonitoring', ['crossTrackingTasks', 'crossTrackingLogs']),
    fields(){
      return  [
        {
          key: '1',
          label:  this.$t('monitoring.table_name'),
          sortable: false
        },
        {
          key: 'distance',
          label:  this.$t('monitoringLocation.tooltip_distance'),
          sortable: false
        },
        // {
        //   key: '2',
        //   label:  this.$t('monitoring.table_action'),
        //   sortable: false,
        //   class: 'actionsBlock'
        // },
      ]
    },
  },
  watch: {
    selectedPairs(){
      if (this.selectedPairs.length  > 0){
        let payload = {
          filters: {
            task_id: this.selectedPairs
          }
        };
        this.$store.dispatch('locationMonitoring/getCrossTrackingTasksLogs', payload).then(()=>{
          this.crossTrackingPoints()
        })
      }
    },
  },
  methods:{

    startSubscription(item){

    },
    stopSubscription(){},
    editSubscription(){},

    crossTrackingPoints(){
      let selectedTargetsIds = [];
      let temp =  this.crossTrackingTasks.find(el=> el.id === this.selectedPairs[0])
      temp.targets.forEach(target => {
        selectedTargetsIds.push(target.target_id)
      })
      this.selectedTargetsIds = selectedTargetsIds;


      if (this.crossTrackingLogs.length){
        this.crossTrackingLogs.forEach((log, logIndex) => {
          log.locations.forEach(location => {
            let index = this.selectedTargetsIds.indexOf(location.target_id)

            const svgTemplate = `<div class="intersectionPin markerIndex-${logIndex} color-${index}"><div class="marker-icon">${logIndex}</div></div>`;
            const icon = L.divIcon({
              className: "custom-div-icon",
              html: svgTemplate,
              iconSize: [8, 8],
              iconAnchor: [4, 4],
              popupAnchor: [0, -2]
            });

            let pointCoordinates = wkt.parse(location.location)
            pointCoordinates.coordinates.reverse();
            let marker = L.marker(pointCoordinates.coordinates, {
              icon: icon,
              log: log,
              logIndex: logIndex
            });
            marker.on('click', this.selectPoint)
            marker.bindTooltip(this.pintTooltip(location), {
              direction: 'top',
              offset: L.point(6, -6)
            });
            marker.addTo(this.drawnItems);
            this.map.fitBounds(this.drawnItems.getBounds());



          })
        })
      }
    },
    selectPoint(log){
      console.log(log)
    },
    pintTooltip(point){
      let loc
      if (this.$i18n.locale === 'ua'){
        loc =  'uk'
      } else {
        loc =  this.$i18n.locale
      }
      let text = ''
      moment.locale(loc);
      let sameDay = moment(point.period_from).isSame(point.period_to, 'day');
      let sameTime = point.period_from === point.period_to
      if (sameDay){
        if (sameTime){
          text += moment(point.period_from, 'X').format('DD MMMM YYYY') +', '+ moment(point.period_from, 'X').format('HH:mm')
        } else {
          text += moment(point.period_from, 'X').format('DD MMMM YYYY') +', '+ moment(point.period_from, 'X').format('HH:mm') +' - ' + moment(point.period_to, 'X').format('HH:mm')
        }
      } else {
        text += moment(point.period_from, 'X').format('DD MMMM YYYY HH:mm') + ' - ' + moment(point.period_to, 'X').format('DD MMMM YYYY HH:mm')
      }
      return text
    },
    async initIntersectionsMap() {
      const locale = drawLocales(this.locale)
      L.drawLocal = locale;
      L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;

      this.map = await L.map('mapIntersections', {
        zoomControl: false,
        attributionControl: false,
        center: this.center,
        zoom: this.startZoom
      });

      L.tileLayer(this.url, { maxZoom: 19, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map)
      L.control.zoom({
        zoomInTitle: this.$t('geo.zoomIn'),
        zoomOutTitle: this.$t('geo.zoomOut')
      }).addTo(this.map)

      var southWest = L.latLng(-89.98155760646617, -180),
          northEast = L.latLng(89.99346179538875, 180);
      var bounds = L.latLngBounds(southWest, northEast);

      this.map.setMaxBounds(bounds);
      this.map.on('drag', () => {
        this.map.panInsideBounds(bounds, { animate: false });
      });
      this.drawnItems = new L.FeatureGroup().addTo(this.map);
    },
    getAreaTrackingTask(){
      this.isLoading = true;
      this.$store.dispatch('locationMonitoring/getCrossTrackingTasks').then(()=> {
        this.isLoading = false;
      })
    },
    rowClass(item){
        let index = this.selectedPairs.findIndex((element) => item.id === element)
        if (index !== -1){
          // return 'color-'+ index
          return 'colored'
        }
    },
  },
  mounted() {
    this.getAreaTrackingTask();
    this.initIntersectionsMap()
  }

}
</script>

<style lang="scss">
.tabContainer {
  .leaflet-tooltip-pane>* {
    width: 300px !important;
    text-align: center !important;
    white-space: normal !important;
  }
  .leaflet-tooltip {
    background-color: white;
    border: 1px solid #ccc;
    padding: 6px 10px;
    font-size: 14px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.4);
  }
  .targetListHeader{
    display: flex;
    justify-content: flex-end;
  }
  .card {
    margin-bottom: 8px;
  }
  .paginationCard {
    padding: 8px 14px;
    .paginationBlock{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  h4 {
    margin-bottom: 0;
  }
  .targetsPairs:last-child{
    margin-left: 10px;
  }

  .intersectionPin{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    .marker-icon {
      position: absolute;
      bottom: -11px;
      left: 3.5px;
      width: 15px;
      height: 15px;
      text-align: center;
      color: black;
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }

  .colored {
    .color-tag-0 {
      background-color: rgba(0, 123, 255, 0.6);
    }

    .color-tag-1 {
      background-color: rgba(40, 167, 69, 0.6);
    }

    .color-tag-2 {
      background-color: rgba(23, 162, 184, 0.6);
    }

    .color-tag-3 {
      background-color: rgba(220, 53, 69, 0.6);
    }

    .color-tag-4 {
      background-color: rgba(55, 71, 79, 0.6);
    }
  }

}
</style>